<template>
  <div>
    <v-row>
      <v-col cols="12" md="8">
        <common-card>
          <v-card-text>
            <v-row>
              <v-col
                class="full-center-div"
                cols="12"
                sm="12"
                md="2"
                lg="2"
                xl="2"
              >
              <v-avatar color="primary" size="70px" style="cursor: pointer;">
                <span class="white--text">
                    {{
                    (
                        name.split(" ")[0][0] +
                        (name.split(" ")[1] ? name.split(" ")[1][0] : "")
                    ).toUpperCase()
                    }}
                </span>
                </v-avatar>
              </v-col>
              <v-col cols="12" xl="8" lg="8" md="8" sm="12">
                <v-row class="full-center-row">
                  <v-col cols="12" style="padding-bottom: 2px;">
                    <h2>
                      {{
                        personalData.nombre +
                          " " +
                          personalData.apellidoPaterno +
                          " " +
                          personalData.apellidoMaterno
                      }}
                    </h2>
                  </v-col>
                  <v-col
                    cols="12"
                    style="padding-top: 2px; padding-bottom: 0px;"
                  >
                    ID {{ personalData.idProspecto }}
                  </v-col>
                  <v-col
                    cols="12"
                    style="padding-top: 2px; padding-bottom: 0px;"
                  >
                    {{ getStateName(personalData.nacimientoEstadoId) }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                xl="2"
                lg="2"
                md="2"
                sm="12"
                class="full-center-div"
              >
                <!-- <v-btn
                            rounded
                            dark
                            class="common-botton"
                        >
                        <span style="padding-right: 11%;">
                            Editar
                        </span>
                            <v-icon rigth>
                                mdi-pencil
                            </v-icon>
                        </v-btn> -->
              </v-col>
            </v-row>
          </v-card-text>
        </common-card>
      </v-col>
      <v-col cols="12" md="4">
        <common-status-docs
          :personalData="personalData"
          :transforToAgent="transforToAgent"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CommonCard from "@/components/commonComponents/CommonCard.vue";
import CommonStatusDocs from "@/components/commonComponents/cards/CommonStatusDocs.vue";

export default {
  components: {
    CommonCard,
    CommonStatusDocs,
  },
  props: {
    personalData: Object,
    stateList: {
      type: Array,
      default: function() {
        return [];
      },
    },
    transforToAgent:{
      type:Boolean,
      default: false
    }
  },
  data() {
    return {
      name: localStorage.agenteName,
    };
  },

  methods: {
    getStateName(id) {
      if (this.stateList.length > 0)
        return this.stateList.find((e) => e.cEstado == id)
          ? this.stateList.find((e) => e.cEstado == id).dEstado
          : "No disponible";
      else return "No disponible";
    },
  },
};
</script>

<style scoped>
.common-botton {
  background-color: #00a7e4 !important;
}

.full-center-div {
  align-self: center;
  text-align: center;
}

.full-center-row {
  padding: 2%;
  padding-left: 10%;
}
@media (max-width: 992px) {
  .full-center-row {
    align-self: center;
    text-align: center;
  }
}
</style>
