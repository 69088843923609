<template>
  <div>
    <common-card>
      <v-card-text style="cursor: pointer;">
        <v-row> 
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <div style="margin: 10px;" @click="toAgent">
              <v-row>
                <v-col cols="2" sm="2" md="2" class="full-center-div" style="padding-right: 20px;">
                  <img :src="tuercaImg" width="30" />
                </v-col>
                <v-col cols="10" sm="10" md="10" class="full-center-div" >
                  <h2 class="text-to-agent">
                    Ver perfil Asesor
                  </h2>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </common-card>
  </div>
</template>

<script>
import CommonCard from "@/components/commonComponents/CommonCard.vue";
import Tuerca from "@/assets/Tuerca.svg";

export default {
  components: {
    CommonCard,
  },

  props: {
    toAgentRoute: {
      type: String,
      default: '/',
    }
  },

  data() {
    return {
      tuercaImg: Tuerca,
    };
  },


  methods: {
    closeModal() {
      this.$emit("closeMenuModal");
    },
    toAgent(){
      this.$router.push(this.toAgentRoute)
    }
  },
};
</script>

<style scoped>
.common-botton {
  background-color: #00a7e4 !important;
}

.full-center-div {
  align-self: center;
}

.full-center-row {
  align-self: center;
  text-align: center;
  min-height: 22vh;
}

.action-button {
  cursor: pointer;
}
.text-to-agent {
  width: 167px;
  height: 22px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 110%;
  display: flex;
  align-items: center;
  color: #4d5358;
  flex: none;
  order: 1;
  flex-grow: 0;
}
</style>
